define("ember-validators/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validate = validate;
  function validate(type) {
    var _validator;
    var validator;
    if (type === 'collection') {
      validator = import_collection();
    } else if (type === 'confirmation') {
      validator = import_confirmation();
    } else if (type === 'date') {
      validator = import_date();
    } else if (type === 'ds-error') {
      validator = import_ds_error();
    } else if (type === 'exclusion') {
      validator = import_exclusion();
    } else if (type === 'format') {
      validator = import_format();
    } else if (type === 'inclusion') {
      validator = import_inclusion();
    } else if (type === 'length') {
      validator = import_length();
    } else if (type === 'messages') {
      validator = import_messages();
    } else if (type === 'number') {
      validator = import_number();
    } else if (type === 'presence') {
      validator = import_presence();
    }
    (true && !(Ember.isPresent(validator)) && Ember.assert("Validator not found of type: ".concat(type, "."), Ember.isPresent(validator)));
    for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }
    return (_validator = validator).default.apply(_validator, args);
  }
  function import_collection() {
    return require("ember-validators/collection");
  }
  function import_confirmation() {
    return require("ember-validators/confirmation");
  }
  function import_date() {
    return require("ember-validators/date");
  }
  function import_ds_error() {
    return require("ember-validators/ds-error");
  }
  function import_exclusion() {
    return require("ember-validators/exclusion");
  }
  function import_format() {
    return require("ember-validators/format");
  }
  function import_inclusion() {
    return require("ember-validators/inclusion");
  }
  function import_length() {
    return require("ember-validators/length");
  }
  function import_messages() {
    return require("ember-validators/messages");
  }
  function import_number() {
    return require("ember-validators/number");
  }
  function import_presence() {
    return require("ember-validators/presence");
  }
});