define("ember-table/components/ember-tr/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{#each this.cells as |api|}}
    {{#if (has-block)}}
      {{#if this.isHeader}}
        {{yield (hash
          columnValue=api.columnValue
          columnMeta=api.columnMeta
  
          sorts=api.sorts
          sendUpdateSort=api.sendUpdateSort
  
          rowMeta=api.rowMeta
          rowsCount=api.rowsCount
  
          cell=(component "ember-th" api=api)
        )}}
      {{else}}
        {{yield (hash
          api=api
  
          cellValue=api.cellValue
          cellMeta=api.cellMeta
  
          columnValue=api.columnValue
          columnMeta=api.columnMeta
  
          rowValue=api.rowValue
          rowMeta=api.rowMeta
  
          rowsCount=api.rowsCount
  
          cell=(component "ember-td" api=api)
        )}}
      {{/if}}
    {{else if this.isHeader}}
      <EmberTh @api={{api}} />
    {{else}}
      <EmberTd @api={{api}} />
    {{/if}}
  {{/each}}
  
  */
  {
    "id": "jqqhfK7i",
    "block": "{\"symbols\":[\"api\",\"&default\"],\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"cells\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,0],[[32,0,[\"isHeader\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,2,[[30,[36,2],null,[[\"columnValue\",\"columnMeta\",\"sorts\",\"sendUpdateSort\",\"rowMeta\",\"rowsCount\",\"cell\"],[[32,1,[\"columnValue\"]],[32,1,[\"columnMeta\"]],[32,1,[\"sorts\"]],[32,1,[\"sendUpdateSort\"]],[32,1,[\"rowMeta\"]],[32,1,[\"rowsCount\"]],[30,[36,1],[\"ember-th\"],[[\"api\"],[[32,1]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[18,2,[[30,[36,2],null,[[\"api\",\"cellValue\",\"cellMeta\",\"columnValue\",\"columnMeta\",\"rowValue\",\"rowMeta\",\"rowsCount\",\"cell\"],[[32,1],[32,1,[\"cellValue\"]],[32,1,[\"cellMeta\"]],[32,1,[\"columnValue\"]],[32,1,[\"columnMeta\"]],[32,1,[\"rowValue\"]],[32,1,[\"rowMeta\"]],[32,1,[\"rowsCount\"]],[30,[36,1],[\"ember-td\"],[[\"api\"],[[32,1]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,0,[\"isHeader\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"ember-th\",[],[[\"@api\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"ember-td\",[],[[\"@api\"],[[32,1]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"component\",\"hash\",\"-track-array\",\"each\"]}",
    "moduleName": "ember-table/components/ember-tr/template.hbs"
  });
  _exports.default = _default;
});