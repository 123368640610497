define("ember-modifier/-private/utils/symbol", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.symbol = void 0;
  /**
   * This symbol provides a Symbol replacement for browsers that do not have it
   * (eg. IE 11).
   *
   * The replacement is different from the native Symbol in some ways. It is a
   * function that produces an output:
   * - iterable;
   * - that is a string, not a symbol.
   *
   * This is inspired by:
   * https://github.com/emberjs/data/blob/master/packages/store/addon/-private/ts-interfaces/utils/symbol.ts
   *
   */
  var symbol = typeof Symbol !== 'undefined' ? Symbol : function (key) {
    return "__".concat(key).concat(Math.floor(Math.random() * Date.now()), "__");
  };
  _exports.symbol = symbol;
});