define("ember-table/components/ember-tfoot/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{#each this.wrappedRowArray as |rowValue|}}
    <EmberTablePrivate::RowWrapper
      @rowValue={{rowValue}}
      @columns={{this.columns}}
      @columnMetaCache={{this.columnMetaCache}}
      @rowMetaCache={{this.rowMetaCache}}
      @canSelect={{this.canSelect}}
      @rowSelectionMode={{this.rowSelectionMode}}
      @checkboxSelectionMode={{this.checkboxSelectionMode}}
      @rowsCount={{this.wrappedRowArray.length}} as |api|
    >
      {{#if (has-block)}}
        {{yield (hash
          rowValue=api.rowValue
          rowMeta=api.rowMeta
          cells=api.cells
          rowSelectionMode=api.rowSelectionMode
          rowsCount=api.rowsCount
  
          row=(component "ember-tr" api=api)
        )}}
      {{else}}
        <EmberTr @api={{api}} />
      {{/if}}
    </EmberTablePrivate::RowWrapper>
  {{/each}}
  
  */
  {
    "id": "Y+yi4KXI",
    "block": "{\"symbols\":[\"rowValue\",\"api\",\"&default\"],\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"wrappedRowArray\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"ember-table-private/row-wrapper\",[],[[\"@rowValue\",\"@columns\",\"@columnMetaCache\",\"@rowMetaCache\",\"@canSelect\",\"@rowSelectionMode\",\"@checkboxSelectionMode\",\"@rowsCount\"],[[32,1],[32,0,[\"columns\"]],[32,0,[\"columnMetaCache\"]],[32,0,[\"rowMetaCache\"]],[32,0,[\"canSelect\"]],[32,0,[\"rowSelectionMode\"]],[32,0,[\"checkboxSelectionMode\"]],[32,0,[\"wrappedRowArray\",\"length\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[27,[32,3]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,3,[[30,[36,1],null,[[\"rowValue\",\"rowMeta\",\"cells\",\"rowSelectionMode\",\"rowsCount\",\"row\"],[[32,2,[\"rowValue\"]],[32,2,[\"rowMeta\"]],[32,2,[\"cells\"]],[32,2,[\"rowSelectionMode\"]],[32,2,[\"rowsCount\"]],[30,[36,0],[\"ember-tr\"],[[\"api\"],[[32,2]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[8,\"ember-tr\",[],[[\"@api\"],[[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"if\",\"-track-array\",\"each\"]}",
    "moduleName": "ember-table/components/ember-tfoot/template.hbs"
  });
  _exports.default = _default;
});