define("ember-table/components/ember-th/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{#if (has-block)}}
    {{yield this.columnValue this.columnMeta this.rowMeta}}
  {{else}}
    {{this.columnValue.name}}
  
    <EmberTh::SortIndicator @columnMeta={{this.columnMeta}} />
  
    <EmberTh::ResizeHandle @columnMeta={{this.columnMeta}} />
  {{/if}}
  
  */
  {
    "id": "GGcsU2cc",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,0],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,[[32,0,[\"columnValue\"]],[32,0,[\"columnMeta\"]],[32,0,[\"rowMeta\"]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[32,0,[\"columnValue\",\"name\"]]],[2,\"\\n\\n  \"],[8,\"ember-th/sort-indicator\",[],[[\"@columnMeta\"],[[32,0,[\"columnMeta\"]]]],null],[2,\"\\n\\n  \"],[8,\"ember-th/resize-handle\",[],[[\"@columnMeta\"],[[32,0,[\"columnMeta\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "ember-table/components/ember-th/template.hbs"
  });
  _exports.default = _default;
});