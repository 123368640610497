define("ember-model-select/components/model-select/spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg
    class="ember-model-select__spinner"
    width="25"
    height="25"
    viewBox="0 0 38 38"
    xmlns="http://www.w3.org/2000/svg"
    style="fill-rule:evenodd; clip-rule:evenodd; fill:none;"
  >
    <circle cx="19" cy="19" r="17" stroke-width="3.9px"/>
    <path d="M36,19c0,-9.388 -7.612,-17 -17,-17" stroke-width="3.9px"/>
  </svg>
  */
  {
    "id": "iX8xOt4Q",
    "block": "{\"symbols\":[],\"statements\":[[10,\"svg\"],[14,0,\"ember-model-select__spinner\"],[14,\"width\",\"25\"],[14,\"height\",\"25\"],[14,\"viewBox\",\"0 0 38 38\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,5,\"fill-rule:evenodd; clip-rule:evenodd; fill:none;\"],[12],[2,\"\\n  \"],[10,\"circle\"],[14,\"cx\",\"19\"],[14,\"cy\",\"19\"],[14,\"r\",\"17\"],[14,\"stroke-width\",\"3.9px\"],[12],[13],[2,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M36,19c0,-9.388 -7.612,-17 -17,-17\"],[14,\"stroke-width\",\"3.9px\"],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "ember-model-select/components/model-select/spinner.hbs"
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});