define("ember-table/components/ember-td/component", ["exports", "ember-table/components/-private/base-table-cell", "ember-table/components/ember-td/template", "ember-table/-private/collapse-tree"], function (_exports, _baseTableCell, _template, _collapseTree) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.setSimpleCheckboxForTest = setSimpleCheckboxForTest;
  var setupSimpleCheckboxForTest = false;
  function setSimpleCheckboxForTest(bool) {
    setupSimpleCheckboxForTest = bool;
  }

  /**
   The table cell component. This component manages cell level concerns, yields
   the cell value, column value, row value, and all of their associated meta
   objects.
  
   ```hbs
   <EmberTable as |t|>
   <t.head @columns={{this.columns}} />
  
   <t.body @rows={{this.rows}} as |b|>
   <b.row as |r|>
   <r.cell as |cellValue columnValue rowValue cellMeta columnMeta rowMeta|>
  
   </r.cell>
   </b.row>
   </t.body>
   </EmberTable>
   ```
  
   @yield {any} cellValue - The value of the cell
   @yield {object} columnValue - The column definition
   @yield {object} rowValue - The row definition
  
   @yield {object} cellMeta - The meta object associated with the cell
   @yield {object} columnMeta - The meta object associated with the column
   @yield {object} rowMeta - The meta object associated with the row
   @class <EmberTd />
   @public
   */
  var _default = _baseTableCell.default.extend({
    layout: _template.default,
    tagName: 'td',
    init: function init() {
      this._super.apply(this, arguments);
      if (setupSimpleCheckboxForTest) {
        this.set('isTesting', true);
      }
    },
    /**
     The API object passed in by the table row
     @argument api
     @required
     @type object
    */
    api: null,
    /**
     Action sent when the user clicks this element
     @argument onClick
     @type Action?
    */
    onClick: null,
    /**
     Action sent when the user double clicks this element
     @argument onDoubleClick
     @type Action?
    */
    onDoubleClick: null,
    // only watch `api` due to a bug in Ember
    unwrappedApi: Ember.computed('api', function () {
      return this.get('api.api') || this.get('api');
    }),
    cellValue: Ember.computed.alias('unwrappedApi.cellValue'),
    cellMeta: Ember.computed.readOnly('unwrappedApi.cellMeta'),
    columnValue: Ember.computed.readOnly('unwrappedApi.columnValue'),
    columnMeta: Ember.computed.readOnly('unwrappedApi.columnMeta'),
    rowValue: Ember.computed.readOnly('unwrappedApi.rowValue'),
    rowMeta: Ember.computed.readOnly('unwrappedApi.rowMeta'),
    rowsCount: Ember.computed.readOnly('unwrappedApi.rowsCount'),
    rowSelectionMode: Ember.computed.readOnly('unwrappedApi.rowSelectionMode'),
    checkboxSelectionMode: Ember.computed.readOnly('unwrappedApi.checkboxSelectionMode'),
    canCollapse: Ember.computed.readOnly('rowMeta.canCollapse'),
    depthClass: Ember.computed('rowMeta.depth', function () {
      return "depth-".concat(this.get('rowMeta.depth'));
    }),
    canSelect: Ember.computed('shouldShowCheckbox', 'rowSelectionMode', function () {
      var rowSelectionMode = this.get('rowSelectionMode');
      var shouldShowCheckbox = this.get('shouldShowCheckbox');
      return shouldShowCheckbox || rowSelectionMode === _collapseTree.SELECT_MODE.MULTIPLE || rowSelectionMode === _collapseTree.SELECT_MODE.SINGLE;
    }),
    shouldShowCheckbox: Ember.computed('checkboxSelectionMode', function () {
      var checkboxSelectionMode = this.get('checkboxSelectionMode');
      return checkboxSelectionMode === _collapseTree.SELECT_MODE.MULTIPLE || checkboxSelectionMode === _collapseTree.SELECT_MODE.SINGLE;
    }),
    actions: {
      onSelectionToggled: function onSelectionToggled(event) {
        var rowMeta = this.get('rowMeta');
        var checkboxSelectionMode = this.get('checkboxSelectionMode') || this.get('rowSelectionMode');
        if (rowMeta && checkboxSelectionMode === _collapseTree.SELECT_MODE.MULTIPLE) {
          var toggle = true;
          var range = event.shiftKey;
          rowMeta.select({
            toggle: toggle,
            range: range
          });
        } else if (rowMeta && checkboxSelectionMode === _collapseTree.SELECT_MODE.SINGLE) {
          rowMeta.select();
        }
        this.sendFullAction('onSelect');
      },
      onCollapseToggled: function onCollapseToggled() {
        var rowMeta = this.get('rowMeta');
        rowMeta.toggleCollapse();
        this.sendFullAction('onCollapse');
      }
    },
    click: function click(event) {
      this.sendFullAction('onClick', {
        event: event
      });
    },
    doubleClick: function doubleClick(event) {
      this.sendFullAction('onDoubleClick', {
        event: event
      });
    },
    sendFullAction: function sendFullAction(action) {
      var values = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      // If the action doesn't exist, it's not being used. Do nothing
      if (!this.get(action)) {
        return;
      }
      var cellValue = this.get('cellValue');
      var cellMeta = this.get('cellMeta');
      var columnValue = this.get('columnValue');
      var columnMeta = this.get('columnMeta');
      var rowValue = this.get('rowValue');
      var rowMeta = this.get('rowMeta');
      Object.assign(values, {
        cellValue: cellValue,
        cellMeta: cellMeta,
        columnValue: columnValue,
        columnMeta: columnMeta,
        rowValue: rowValue,
        rowMeta: rowMeta
      });
      var closureAction = this[action];
      closureAction === null || closureAction === void 0 ? void 0 : closureAction(values);
    }
  });
  _exports.default = _default;
});