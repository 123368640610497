define("ember-infinity/components/infinity-loader", ["exports", "ember-infinity/templates/components/infinity-loader"], function (_exports, _infinityLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var InfinityLoaderComponent = Ember.Component.extend({
    layout: _infinityLoader.default,
    infinity: Ember.inject.service(),
    inViewport: Ember.inject.service(),
    tagName: '',
    /**
     * @public
     * @property eventDebounce
     * @default 50
     */
    eventDebounce: 50,
    /**
     * @public
     * @property loadingText
     */
    loadingText: 'Loading Infinity Model...',
    /**
     * @public
     * @property loadedText
     */
    loadedText: 'Infinity Model Entirely Loaded.',
    /**
     * @public
     * @property hideOnInfinity
     * @default false
     */
    hideOnInfinity: false,
    /**
     * @public
     * @property isDoneLoading
     * @default false
     */
    isDoneLoading: false,
    /**
     * @public
     * @property developmentMode
     * @default false
     */
    developmentMode: false,
    /**
     * indicate to infinity-loader to load previous page
     *
     * @public
     * @property loadPrevious
     * @default false
     */
    loadPrevious: false,
    /**
     * set if have scrollable area
     *
     * @public
     * @property scrollable
     */
    scrollable: null,
    /**
     * offset from bottom of target and viewport
     *
     * @public
     * @property triggerOffset
     * @defaul 0
     */
    triggerOffset: 0,
    /**
     * https://emberjs.com/api/ember/3.0/classes/Component/properties/isVisible?anchor=isVisible
     *
     * @property isVisible
     */
    isVisible: true,
    loaderClassNames: Ember.computed('classNames', function () {
      return 'infinity-loader '.concat(this.classNames).trim();
    }),
    init: function init() {
      this._super.apply(this, arguments);
      Ember.defineProperty(this, 'infinityModelContent', Ember.computed('infinityModel', function () {
        return Promise.resolve(this.infinityModel);
      }));
      this.addObserver('infinityModel', this, this._initialInfinityModelSetup);
      this._initialInfinityModelSetup();
      this.addObserver('hideOnInfinity', this, this._loadStatusDidChange);
      this.addObserver('reachedInfinity', this, this._loadStatusDidChange);
    },
    /**
     * setup ember-in-viewport properties
     *
     * @method didInsertElement
     */
    didInsertLoader: function didInsertLoader(element, _ref) {
      var _ref2 = _slicedToArray(_ref, 1),
        instance = _ref2[0];
      /**
       * @public
       * @property loadingText
       */
      Ember.set(instance, 'loadingText', instance.loadingText || 'Loading Infinity Model...');
      /**
       * @public
       * @property loadedText
       */
      Ember.set(instance, 'loadedText', instance.loadedText || 'Infinity Model Entirely Loaded.');
      instance.elem = element;
      var options = {
        viewportSpy: true,
        viewportTolerance: {
          top: 0,
          right: 0,
          bottom: instance.triggerOffset,
          left: 0
        },
        scrollableArea: instance.scrollable
      };
      var _instance$inViewport$ = instance.inViewport.watchElement(element, options),
        onEnter = _instance$inViewport$.onEnter,
        onExit = _instance$inViewport$.onExit;
      onEnter(instance.didEnterViewport.bind(instance));
      onExit(instance.didExitViewport.bind(instance));
    },
    willDestroy: function willDestroy() {
      var _this = this;
      this._cancelTimers();
      Ember.get(this, 'infinityModelContent').then(function (infinityModel) {
        infinityModel.off('infinityModelLoaded', _this, _this._loadStatusDidChange.bind(_this));
      });
      this.removeObserver('infinityModel', this, this._initialInfinityModelSetup);
      this.removeObserver('hideOnInfinity', this, this._loadStatusDidChange);
      this.removeObserver('reachedInfinity', this, this._loadStatusDidChange);
    },
    /**
     * https://github.com/DockYard/ember-in-viewport#didenterviewport-didexitviewport
     *
     * @method didEnterViewport
     */
    didEnterViewport: function didEnterViewport() {
      if (this.developmentMode || typeof FastBoot !== 'undefined' || this.isDestroying || this.isDestroyed) {
        return false;
      }
      if (Ember.get(this, 'loadPrevious')) {
        return this._debounceScrolledToTop();
      }
      return this._debounceScrolledToBottom();
    },
    /**
     * https://github.com/DockYard/ember-in-viewport#didenterviewport-didexitviewport
     *
     * @method didExitViewport
     */
    didExitViewport: function didExitViewport() {
      this._cancelTimers();
    },
    /**
     * @method _initialInfinityModelSetup
     */
    _initialInfinityModelSetup: function _initialInfinityModelSetup() {
      var _this2 = this;
      Ember.get(this, 'infinityModelContent').then(function (infinityModel) {
        if (_this2.isDestroyed || _this2.isDestroying) {
          return;
        }
        infinityModel.on('infinityModelLoaded', _this2._loadStatusDidChange.bind(_this2));
        Ember.set(infinityModel, '_scrollable', Ember.get(_this2, 'scrollable'));
        Ember.set(_this2, 'isDoneLoading', false);
        if (!Ember.get(_this2, 'hideOnInfinity')) {
          Ember.set(_this2, 'isVisible', true);
        }
        _this2._loadStatusDidChange();
      });
    },
    /**
     * @method _loadStatusDidChange
     */
    _loadStatusDidChange: function _loadStatusDidChange() {
      var _this3 = this;
      Ember.get(this, 'infinityModelContent').then(function (infinityModel) {
        if (_this3.isDestroyed || _this3.isDestroying) {
          return;
        }
        if (Ember.get(infinityModel, 'reachedInfinity')) {
          Ember.set(_this3, 'isDoneLoading', true);
          if (Ember.get(_this3, 'hideOnInfinity')) {
            Ember.set(_this3, 'isVisible', false);
          }
        } else {
          Ember.set(_this3, 'isVisible', true);
        }
      });
    },
    /**
     * only load previous page if route started on a page greater than 1 && currentPage is > 0
     *
     * @method _debounceScrolledToTop
     */
    _debounceScrolledToTop: function _debounceScrolledToTop() {
      var _this4 = this;
      /*
       This debounce is needed when there is not enough delay between onScrolledToBottom calls.
       Without this debounce, all rows will be rendered causing immense performance problems
       */
      function loadPreviousPage(content) {
        if (typeof Ember.get(this, 'infinityLoad') === 'function') {
          // closure action
          return Ember.get(this, 'infinityLoad')(content, -1);
        } else {
          Ember.get(this, 'infinity').infinityLoad(content, -1);
        }
      }
      Ember.get(this, 'infinityModelContent').then(function (content) {
        if (Ember.get(content, 'firstPage') > 1 && Ember.get(content, 'currentPage') > 0) {
          _this4._debounceTimer = Ember.run.debounce(_this4, loadPreviousPage, content, Ember.get(_this4, 'eventDebounce'));
        }
      });
    },
    /**
     * @method _debounceScrolledToBottom
     */
    _debounceScrolledToBottom: function _debounceScrolledToBottom() {
      /*
       This debounce is needed when there is not enough delay between onScrolledToBottom calls.
       Without this debounce, all rows will be rendered causing immense performance problems
       */
      function loadMore() {
        var _this5 = this;
        // resolve to create thennable
        // type is <InfinityModel|Promise|null>
        Ember.get(this, 'infinityModelContent').then(function (content) {
          if (typeof Ember.get(_this5, 'infinityLoad') === 'function') {
            // closure action (if you need to perform some other logic)
            return Ember.get(_this5, 'infinityLoad')(content);
          } else {
            // service action
            Ember.get(_this5, 'infinity').infinityLoad(content, 1).then(function () {
              if (Ember.get(content, 'canLoadMore')) {
                _this5._checkScrollableHeight();
              }
            });
          }
        });
      }
      this._debounceTimer = Ember.run.debounce(this, loadMore, Ember.get(this, 'eventDebounce'));
    },
    /**
     * recursive function to fill page with records
     *
     * @method _checkScrollableHeight
     */
    _checkScrollableHeight: function _checkScrollableHeight() {
      if (this.isDestroying || this.isDestroyed) {
        return false;
      }
      if (this._viewportBottom() > this.elem.getBoundingClientRect().top) {
        // load again
        this._debounceScrolledToBottom();
      }
    },
    /**
     * @method _cancelTimers
     */
    _cancelTimers: function _cancelTimers() {
      Ember.run.cancel(this._debounceTimer);
    },
    /**
      calculate the bottom of the viewport
       @private
      @method _viewportBottom
      @return Integer
     */
    _viewportBottom: function _viewportBottom() {
      if (typeof FastBoot === 'undefined') {
        var isScrollable = !!this.scrollable;
        var viewportElem = isScrollable ? document.querySelector(this.scrollable) : window;
        return isScrollable ? viewportElem.getBoundingClientRect().bottom : viewportElem.innerHeight;
      }
    }
  });
  var _default = InfinityLoaderComponent;
  _exports.default = _default;
});