define("ember-set-helper/helpers/set", ["exports", "ember-set-helper/helpers/-set-placeholder"], function (_exports, _setPlaceholder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  function set(positional) {
    var _positional = _slicedToArray(positional, 3),
      target = _positional[0],
      key = _positional[1],
      valueOrPlaceholder = _positional[2];
    (true && !(positional.length > 2) && Ember.assert('you must pass a path and a value to the `(set)` helper. The value can be a defered value, using placeholder syntax, e.g. `(set this.value _)`', positional.length > 2));
    (true && !(positional.length === 3) && Ember.assert('you cannot pass more than a path and a value to set', positional.length === 3));
    (true && !(Boolean(target) && typeof key === 'string' || _typeof(key) === 'symbol') && Ember.assert('you must pass a path to {{set}}', Boolean(target) && typeof key === 'string' || _typeof(key) === 'symbol'));
    if (valueOrPlaceholder instanceof _setPlaceholder.Placeholder) {
      return function (_value) {
        var path = valueOrPlaceholder.path;
        var value = path === null ? _value : Ember.get(_value, path);
        return Ember.set(target, key, value);
      };
    } else {
      return function () {
        return Ember.set(target, key, valueOrPlaceholder);
      };
    }
  }
  var _default = Ember.Helper.helper(set);
  _exports.default = _default;
});