define("ember-drag-drop/utils/proxy-unproxy-objects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unwrapper = unwrapper;
  _exports.wrapper = wrapper;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  /**
   * On drag action we need to provide a property `isDraggingObject` to the UI.
   * This utility is used to create a wrapper object around the object passed to the proxy function.
   * We use this wrapper to prevent the `draggable-object` from mutating the original object by appending
   * `isDraggingObject` to the content.
   * 
   * This unexpected mutation causes problems when the targeted content is not prepared to handle
   * the additional property, and potentially leaks local state onto an object that likely holds state
   * for the route or application more generally.
   */

  /**
   * @access public
   * Returns the passed object wrapped within new object.
   * Used to proxy draggable objects.
   * @param objectToProxy Object to proxy.
   * @returns {Object} Proxy object.
   */
  function wrapper(objectToProxy) {
    // If we do not have any content for the object to proxy, 
    // we do not wish to render that item.
    if (!Ember.isNone(objectToProxy)) {
      var _ref;
      var guidKey = Ember.guidFor(objectToProxy);
      return _ref = {}, _defineProperty(_ref, guidKey, objectToProxy), _defineProperty(_ref, "unwrappingKey", guidKey), _defineProperty(_ref, "id", objectToProxy.id), _ref;
    }
    return null;
  }

  /**
   * @access public
   * Returns the content of the passed object.
   * Used to un-proxy draggable objects.
   * @param objectToUnproxy Object to un-proxy.
   * @returns {Object} Content of the proxy object.
   */
  function unwrapper(objectToUnproxy) {
    if (!Ember.isNone(objectToUnproxy)) {
      return objectToUnproxy[objectToUnproxy.unwrappingKey];
    }
    return null;
  }
});