define("ember-gestures/utils/string/capitalize-words", ["exports", "ember-gestures/utils/string/cap-first-letter"], function (_exports, _capFirstLetter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(sentence) {
    return sentence.split(' ').map(function (word) {
      return (0, _capFirstLetter.default)(word);
    }).join(' ');
  }
});