define("ember-table/components/ember-tbody/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{#vertical-collection
    items=this.wrappedRows
    containerSelector=this._containerSelector
  
    estimateHeight=this.estimateRowHeight
    key=this.key
    staticHeight=this.staticHeight
    bufferSize=this.bufferSize
    renderAll=this.renderAll
  
    firstReached=this.firstReached
    lastReached=this.lastReached
    firstVisibleChanged=this.firstVisibleChanged
    lastVisibleChanged=this.lastVisibleChanged
    idForFirstItem=this.idForFirstItem
  
    as |rowValue|
  }}
    <EmberTablePrivate::RowWrapper
      @rowValue={{rowValue}}
      @columns={{this.columns}}
      @columnMetaCache={{this.columnMetaCache}}
      @rowMetaCache={{this.rowMetaCache}}
      @canSelect={{this.canSelect}}
      @checkboxSelectionMode={{this.checkboxSelectionMode}}
      @rowSelectionMode={{this.rowSelectionMode}}
      @rowToggleMode={{this.rowToggleMode}}
      @rowsCount={{this.wrappedRows.length}} as |api|
    >
      {{#if (has-block)}}
        {{yield (hash
          rowValue=api.rowValue
          rowMeta=api.rowMeta
          cells=api.cells
          rowSelectionMode=api.rowSelectionMode
          rowToggleMode=api.rowToggleMode
          rowsCount=api.rowsCount
  
          row=(component "ember-tr" api=api)
        )}}
      {{else}}
        <EmberTr @api={{api}} />
      {{/if}}
    </EmberTablePrivate::RowWrapper>
  
  {{else}}
    {{yield to='inverse'}}
  {{/vertical-collection}}
  
  */
  {
    "id": "k6Xt5Eak",
    "block": "{\"symbols\":[\"rowValue\",\"api\",\"&else\",\"&default\"],\"statements\":[[6,[37,3],null,[[\"items\",\"containerSelector\",\"estimateHeight\",\"key\",\"staticHeight\",\"bufferSize\",\"renderAll\",\"firstReached\",\"lastReached\",\"firstVisibleChanged\",\"lastVisibleChanged\",\"idForFirstItem\"],[[32,0,[\"wrappedRows\"]],[32,0,[\"_containerSelector\"]],[32,0,[\"estimateRowHeight\"]],[32,0,[\"key\"]],[32,0,[\"staticHeight\"]],[32,0,[\"bufferSize\"]],[32,0,[\"renderAll\"]],[32,0,[\"firstReached\"]],[32,0,[\"lastReached\"]],[32,0,[\"firstVisibleChanged\"]],[32,0,[\"lastVisibleChanged\"]],[32,0,[\"idForFirstItem\"]]]],[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"ember-table-private/row-wrapper\",[],[[\"@rowValue\",\"@columns\",\"@columnMetaCache\",\"@rowMetaCache\",\"@canSelect\",\"@checkboxSelectionMode\",\"@rowSelectionMode\",\"@rowToggleMode\",\"@rowsCount\"],[[32,1],[32,0,[\"columns\"]],[32,0,[\"columnMetaCache\"]],[32,0,[\"rowMetaCache\"]],[32,0,[\"canSelect\"]],[32,0,[\"checkboxSelectionMode\"]],[32,0,[\"rowSelectionMode\"]],[32,0,[\"rowToggleMode\"]],[32,0,[\"wrappedRows\",\"length\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[27,[32,4]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,4,[[30,[36,1],null,[[\"rowValue\",\"rowMeta\",\"cells\",\"rowSelectionMode\",\"rowToggleMode\",\"rowsCount\",\"row\"],[[32,2,[\"rowValue\"]],[32,2,[\"rowMeta\"]],[32,2,[\"cells\"]],[32,2,[\"rowSelectionMode\"]],[32,2,[\"rowToggleMode\"]],[32,2,[\"rowsCount\"]],[30,[36,0],[\"ember-tr\"],[[\"api\"],[[32,2]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[8,\"ember-tr\",[],[[\"@api\"],[[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[2]}]]],[2,\"\\n\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"  \"],[18,3,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"if\",\"vertical-collection\"]}",
    "moduleName": "ember-table/components/ember-tbody/template.hbs"
  });
  _exports.default = _default;
});