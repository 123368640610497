define("ember-gestures/components/gesture-element", ["exports", "ember-gestures/templates/components/gesture-element", "ember-gestures/mixins/recognizers", "ember-gestures/utils/string/dasherized-to-camel"], function (_exports, _gestureElement, _recognizers, _dasherizedToCamel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function makeActionHandler(event, action) {
    return function actionHandler() {
      var target = this.get('target');
      var args;
      if (target && target.send) {
        args = this._getParams(action);
        target.send.apply(this, args);
      } else {
        args = this._getParams(event + 'Action');
        this.sendAction.apply(this, args); // eslint-disable-line
      }
    };
  }

  /**!
   *
   * Provides the ability to easily build a
   * gesture-ful async-button implementation
   *
   */
  var _default = Ember.Component.extend(_recognizers.default, {
    layout: _gestureElement.default,
    context: '',
    _getParams: function _getParams(actionName) {
      var context = this.get('context');
      return [actionName, context];
    },
    init: function init() {
      this._super();
      var v;
      var attrs = this.get('attrs') || this;
      for (var key in attrs) {
        if (attrs.hasOwnProperty(key)) {
          v = attrs[key];
          if (v === 'toString') {
            continue;
          } // ignore useless items
          if (Ember.typeOf(v) === 'function') {
            continue;
          }

          //setup listener for key
          if (key.indexOf('on-') === 0) {
            var event = (0, _dasherizedToCamel.default)(key.substr(3));
            var action = attrs[key];
            this.set(event + 'Action', action);
            this.set(event, makeActionHandler(event, action));
          }
        }
      }
    }
  });
  _exports.default = _default;
});