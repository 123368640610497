define("ember-model-select/initializers/define-task-test-waiter-modifier", ["exports", "ember-concurrency-test-waiter/define-modifier"], function (_exports, _defineModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize( /* application */
  ) {
    (0, _defineModifier.default)();
  }
  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});