define("ember-leaflet/components/point-path-layer", ["exports", "ember-leaflet/components/path-layer", "ember-leaflet/macros/to-lat-lng"], function (_exports, _pathLayer, _toLatLng) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _pathLayer.default.extend({
    leafletRequiredOptions: Object.freeze(['location']),
    leafletProperties: Object.freeze(['location:setLatLng']),
    location: (0, _toLatLng.default)()
  });
  _exports.default = _default;
});