define("ember-power-select/components/power-select/no-matches-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @noMatchesMessage}}
    <ul class="ember-power-select-options" role="listbox">
      <li class="ember-power-select-option ember-power-select-option--no-matches-message" role="option">
        {{@noMatchesMessage}}
      </li>
    </ul>
  {{/if}}
  */
  {
    "id": "e1arW6+4",
    "block": "{\"symbols\":[\"@noMatchesMessage\"],\"statements\":[[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"ul\"],[14,0,\"ember-power-select-options\"],[14,\"role\",\"listbox\"],[12],[2,\"\\n    \"],[10,\"li\"],[14,0,\"ember-power-select-option ember-power-select-option--no-matches-message\"],[14,\"role\",\"option\"],[12],[2,\"\\n      \"],[1,[32,1]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "ember-power-select/components/power-select/no-matches-message.hbs"
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});