define("ember-light-table/utils/css-styleify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = cssStyleify;
  function cssStyleify() {
    var hash = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var styles = [];
    Object.keys(hash).forEach(function (key) {
      var value = hash[key];
      if (Ember.isPresent(value)) {
        styles.push("".concat(Ember.String.dasherize(key), ": ").concat(value));
      }
    });
    return Ember.String.htmlSafe(styles.join('; '));
  }
});