define("ember-cli-notifications/templates/components/notification-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "1ZgDQ78P",
    "block": "{\"symbols\":[\"notification\"],\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"notifications\",\"content\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],null,[[\"notification\"],[[32,1]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"notification-message\",\"-track-array\",\"each\"]}",
    "moduleName": "ember-cli-notifications/templates/components/notification-container.hbs"
  });
  _exports.default = _default;
});