define("ember-model-select/utils/get-config-option", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getConfigOption;
  /**
   *  Get's the passed configuration option from the `ember-model-select`
   *  environment key or the passed default if the config option is not set.
   *
   * @function getConfigOption
   * @param {String} key
   * @param {*} defaultValue
   * @return {*} The config option or the default value
   * @private
   * @hide
   */
  function getConfigOption(key, defaultValue) {
    return Ember.getWithDefault(_emberGetConfig.default, "ember-model-select.".concat(key), defaultValue);
  }
});