define("ember-svg-jar/inlined/cancel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9 0C4.05 0 0 4.05 0 9s4.05 9 9 9 9-4.05 9-9-4.05-9-9-9zm0 2c1.55 0 3 .55 4.2 1.4l-9.8 9.8C2.55 12 2 10.55 2 9c0-3.85 3.15-7 7-7zm0 14c-1.55 0-3-.55-4.2-1.4l9.8-9.8C15.45 6 16 7.45 16 9c0 3.85-3.15 7-7 7z\" fill=\"#F90A0A\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});