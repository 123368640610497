define("ember-leaflet/components/interactive-layer", ["exports", "ember-leaflet/components/base-layer"], function (_exports, _baseLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _baseLayer.default.extend({
    leafletOptions: Object.freeze(['interactive', 'bubblingMouseEvents']),
    leafletEvents: Object.freeze(['click', 'dblclick', 'mousedown', 'mouseup', 'mouseover', 'mouseout', 'contextmenu'])
  });
  _exports.default = _default;
});