define("ember-gestures/utils/string/capitalize-word", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(s) {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }
});