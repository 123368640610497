define("ember-concurrency-test-waiter/define-modifier", ["exports", "ember-concurrency-test-waiter/with-test-waiter", "ember-concurrency/-task-property"], function (_exports, _withTestWaiter, _taskProperty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = defineModifier;
  // Define a .withTestWaiter() modifier on TaskProperty
  function defineModifier() {
    _taskProperty.TaskProperty.prototype.withTestWaiter = function () {
      return (0, _withTestWaiter.default)(this);
    };
  }
});