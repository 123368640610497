define("ember-drag-drop/components/draggable-object", ["exports", "ember-drag-drop/utils/proxy-unproxy-objects"], function (_exports, _proxyUnproxyObjects) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  var _default = Ember.Component.extend({
    dragCoordinator: Ember.inject.service('drag-coordinator'),
    overrideClass: 'draggable-object',
    classNameBindings: [':js-draggableObject', 'isDraggingObject:is-dragging-object:', 'overrideClass'],
    attributeBindings: ['dragReady:draggable'],
    isDraggable: true,
    dragReady: true,
    isSortable: false,
    sortingScope: 'drag-objects',
    title: Ember.computed.alias('content.title'),
    // idea taken from https://github.com/emberjs/rfcs/blob/master/text/0680-implicit-injection-deprecation.md#stage-1
    get coordinator() {
      if (this._coordinator === undefined) {
        this._coordinator = Ember.getOwner(this).lookup('drag:coordinator');
      }
      return this._coordinator;
    },
    set coordinator(value) {
      this._coordinator = value;
    },
    draggable: Ember.computed('isDraggable', function () {
      var isDraggable = this.get('isDraggable');
      return isDraggable || null;
    }),
    proxyContent: Ember.computed('content', function () {
      return (0, _proxyUnproxyObjects.wrapper)(this.get('content'));
    }),
    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('dragHandle')) {
        this.set('dragReady', false);
      }
      this.mouseOverHandler = function () {
        this.set('dragReady', true);
      }.bind(this);
      this.mouseOutHandler = function () {
        this.set('dragReady', false);
      }.bind(this);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      Ember.run.scheduleOnce('afterRender', function () {
        //if there is a drag handle watch the mouse up and down events to trigger if drag is allowed
        var dragHandle = _this.get('dragHandle');
        if (dragHandle) {
          //only start when drag handle is activated
          if (_this.element.querySelector(dragHandle)) {
            _this.element.querySelector(dragHandle).addEventListener('mouseover', _this.mouseOverHandler);
            _this.element.querySelector(dragHandle).addEventListener('mouseout', _this.mouseOutHandler);
          }
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      var dragHandle = this.get('dragHandle');
      if (this.element.querySelector(dragHandle)) {
        this.element.querySelector(dragHandle).removeEventListener('mouseover', this.mouseOverHandler);
        this.element.querySelector(dragHandle).removeEventListener('mouseout', this.mouseOutHandler);
      }
    },
    dragStart: function dragStart(event) {
      var _this2 = this;
      if (!this.get('isDraggable') || !this.get('dragReady')) {
        event.preventDefault();
        return;
      }
      var dataTransfer = event.dataTransfer;
      var obj = this.get('proxyContent');
      var id = null;
      var coordinator = this.get('coordinator');
      if (coordinator) {
        id = coordinator.setObject(obj, {
          source: this
        });
      }
      dataTransfer.setData('Text', id);
      if (obj && _typeof(obj) === 'object') {
        Ember.set(obj, 'isDraggingObject', true);
      }
      this.set('isDraggingObject', true);
      if (!this.get('dragCoordinator.enableSort') && this.get('dragCoordinator.sortComponentController')) {
        //disable drag if sorting is disabled this is not used for regular
        event.preventDefault();
        return;
      } else {
        Ember.run.next(function () {
          _this2.dragStartHook(event);
        });
        this.get('dragCoordinator').dragStarted(obj, event, this);
      }
      if (this.get('dragStartAction')) {
        this.get('dragStartAction')(obj, event);
      }
      if (this.get('isSortable') && this.get('draggingSortItem')) {
        this.get('draggingSortItem')(obj, event);
      }
    },
    dragEnd: function dragEnd(event) {
      if (!this.get('isDraggingObject')) {
        return;
      }
      var obj = this.get('proxyContent');
      if (obj && _typeof(obj) === 'object') {
        Ember.set(obj, 'isDraggingObject', false);
      }
      this.set('isDraggingObject', false);
      this.dragEndHook(event);
      this.get('dragCoordinator').dragEnded();
      if (this.get('dragEndAction')) {
        this.get('dragEndAction')(obj, event);
      }
      if (this.get('dragHandle')) {
        this.set('dragReady', false);
      }
    },
    drag: function drag(event) {
      if (this.get('dragMoveAction')) {
        this.get('dragMoveAction')(event);
      }
    },
    dragOver: function dragOver(event) {
      if (this.get('isSortable')) {
        this.get('dragCoordinator').draggingOver(event, this);
      }
      return false;
    },
    dragStartHook: function dragStartHook(event) {
      event.target.style.opacity = '0.5';
    },
    dragEndHook: function dragEndHook(event) {
      event.target.style.opacity = '1';
    },
    drop: function drop(event) {
      //Firefox is navigating to a url on drop, this prevents that from happening
      event.preventDefault();
    },
    actions: {
      selectForDrag: function selectForDrag() {
        var obj = this.get('proxyContent');
        var hashId = this.get('coordinator').setObject(obj, {
          source: this
        });
        this.set('coordinator.clickedId', hashId);
      }
    }
  });
  _exports.default = _default;
});