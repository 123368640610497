define("ember-bootstrap-modals-manager/instance-initializers/patch-bootstrap-modals-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(appInstance) {
    var modalsManager = appInstance.lookup('service:modals-manager');
    Ember.setProperties(modalsManager.defaultOptions, {
      size: null,
      backdropTransitionDuration: 150,
      renderInPlace: true,
      transitionDuration: 300,
      confirmIsActive: false,
      confirmButtonSize: 'md',
      confirmButtonType: 'primary',
      confirmIconActive: '',
      confirmIconInactive: '',
      declineIsActive: false,
      declineButtonSize: 'md',
      declineButtonType: 'secondary',
      declineIconActive: '',
      declineIconInactive: ''
    });
  }
  var _default = {
    name: 'bootstrap-modals-manager',
    initialize: initialize
  };
  _exports.default = _default;
});