define("ember-leaflet/mixins/div-overlayable", ["exports", "ember-composability-tools", "ember-leaflet/templates/div-overlayable"], function (_exports, _emberComposabilityTools, _divOverlayable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create(_emberComposabilityTools.ParentMixin, _emberComposabilityTools.ChildMixin, {
    layout: _divOverlayable.default
  });
  _exports.default = _default;
});