define("ember-leaflet/mixins/draggability", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    // eslint-disable-next-line ember/no-observers
    draggableDidChange: Ember.observer('draggable', function () {
      if (this.get('draggable')) {
        this._layer.dragging.enable();
      } else {
        this._layer.dragging.disable();
      }
    })
  });
  _exports.default = _default;
});