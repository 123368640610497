define("ember-light-table/-private/global-options", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.mergeOptionsWithGlobals = mergeOptionsWithGlobals;
  var globalOptions = _emberGetConfig.default['ember-light-table'] || {};
  var _default = globalOptions;
  _exports.default = _default;
  function mergeOptionsWithGlobals(options) {
    return Ember.assign({}, globalOptions, options);
  }
});