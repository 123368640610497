define("ember-power-calendar/-private/utils/own-prop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = ownProp;
  function ownProp(obj, prop) {
    return Object.prototype.hasOwnProperty.call(obj, prop);
  }
});